import PropTypes from 'prop-types'

export const linkPropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string.isRequired,
})

export const gravityFormFieldProps = {
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf([
    'TEXT',
    'TEXTAREA',
    'EMAIL',
    'PHONE',
    'SELECT',
    'CONSENT',
    'CAPTCHA',
  ]),
  visibility: PropTypes.oneOf(['VISIBLE', 'INVISIBLE']),
  columnSpan: PropTypes.number,

  // TEXT, TEXTAREA, EMAIL, SELECT, CONSENT
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string,

  // CONSENT
  checkboxLabel: PropTypes.string,
  consentValue: PropTypes.string,

  // CAPTCHA
  captchaBadgePosition: PropTypes.oneOf([
    'BOTTOM_RIGHT',
    'BOTTOM_LEFT',
    'INLINE',
  ]),
  captchaTheme: PropTypes.oneOf(['LIGHT', 'DARK']),
}

export const gravityFormFieldDefaultValues = {
  columnSpan: 12,
}

export const gravityFormPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  databaseId: PropTypes.number.isRequired,
  submitButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
  confirmations: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    })
  ),
  formFields: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.shape(gravityFormFieldProps)),
  }),
})
