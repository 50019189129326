import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../breadcrumbs'
import GravityForm from '../gravity-form'

const ContactLayout = ({ breadcrumbs, title, form, location }) => {
  return (
    <div
      className={
        'xl-container mx-auto flex flex-col pb-8 lg:pb-6 gap-y-6 md:gap-y-7 lg:gap-y-8'
      }
    >
      <Breadcrumbs crumbs={breadcrumbs} />
      <h1 className={'md:text-center text-4xl md:text-5xl font-medium'}>
        {title}
      </h1>

      <div className={'flex flex-col w-full items-center'}>
        <div className={'w-full max-w-md'}>
          <GravityForm gravityForm={form} location={location} />
        </div>
      </div>
    </div>
  )
}

export default ContactLayout

ContactLayout.propTypes = {
  breadcrumbs: PropTypes.array,
  title: PropTypes.string,
}
