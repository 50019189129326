import React from 'react'
import toast from 'react-hot-toast'
import { CloseIcon } from '../../images/close-icon'

export const contactFormToast = () =>
  toast(
    <div className={'flex gap-x-2 justify-center content-center items-center'}>
      <p className={'text-blue-dark font-medium text-sm'}>
        Din henvendelse er sendt
      </p>
      <span
        className={'hover:bg-white/40 rounded-full'}
        onClick={() => toast.dismiss()}
      >
        <CloseIcon size={'24'} />
      </span>
    </div>,
    {
      duration: 4000,
      position: 'top-right',
      className: 'bg-blue-dark',
      style: {
        textColor: '#001529',
        backgroundColor: '#C5F9BC',
        padding: '8px 4px 8px 10px',
      },
    }
  )
