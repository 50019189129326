import * as React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const getSpanClassName = (layoutGridColumnSpan) => {
  switch (layoutGridColumnSpan) {
    case 11:
      return 'md:col-span-11'
    case 9:
      return 'md:col-span-9'
    case 8:
      return 'md:col-span-8'
    case 7:
      return 'md:col-span-7'
    case 6:
      return 'md:col-span-6'
    case 5:
      return 'md:col-span-5'
    case 4:
      return 'md:col-span-4'
    case 3:
      return 'md:col-span-3'
    case 12:
    default:
      return 'col-span-12'
  }
}

const FieldWrapper = ({ columnSpan, visibility, children, className }) => {
  const classNames = cx([
    getSpanClassName(columnSpan),
    visibility !== 'VISIBLE' ? 'hidden' : '',
    className,
  ])

  return <div className={classNames}>{children}</div>
}

FieldWrapper.propTypes = {
  columnSpan: PropTypes.number.isRequired,
  visibility: PropTypes.oneOf(['VISIBLE', 'INVISIBLE']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

FieldWrapper.defaultProps = {
  columnSpan: 12,
}

export default FieldWrapper
