import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import ContactLayout from '../components/contact'
import SEO from '../components/seo'

const ContactTemplate = ({ data, location }) => {
  const { wpPage: page, wpGfForm: form } = data

  return (
    <Layout location={location}>
      <SEO
        title={page.title}
        metaTitle={page.seo.title}
        description={page.seo.metaDesc}
      />
      <ContactLayout
        location={location}
        breadcrumbs={page.seo.breadcrumbs}
        title={page.title}
        form={form}
      />
    </Layout>
  )
}

export default ContactTemplate

export const query = graphql`
  query ($id: String!, $formId: Int!) {
    wpPage(id: { eq: $id }) {
      title
      seo {
        breadcrumbs {
          text
          url
        }
        metaDesc
        title
      }
    }
    wpGfForm(databaseId: { eq: $formId }, isActive: { eq: true }) {
      id
      formId: databaseId
      submitButton {
        text
      }
      confirmations {
        message
      }
      formFields {
        nodes {
          id
          type
          visibility

          columnSpan: layoutGridColumnSpan

          ... on WpTextField {
            label
            description
            placeholder
            isRequired
            errorMessage
            defaultValue
          }
          ... on WpEmailField {
            label
            description
            placeholder
            isRequired
            errorMessage
          }
          ... on WpPhoneField {
            label
            description
            placeholder
            isRequired
            errorMessage
            phoneFormat
            defaultValue
          }
          ... on WpSelectField {
            label
            description
            placeholder
            isRequired
            errorMessage
            defaultValue
            choices {
              text
              value
              isSelected
            }
          }
          ... on WpConsentField {
            label
            description
            isRequired
            checkboxLabel
            consentValue
          }
          ... on WpCaptchaField {
            captchaBadgePosition
            captchaTheme
          }
          ... on WpTextAreaField {
            label
            placeholder
            description
            isRequired
            defaultValue
          }
        }
      }
    }
  }
`
