import * as Yup from 'yup'

const INVALID_MESSAGE = 'You entered an invalid value'

const genericStringValidator = (field) => {
  const validation = field.isRequired
    ? Yup.string().required(field.errorMessage || INVALID_MESSAGE)
    : Yup.string()
  //  field.isRequired && validation.required(field.errorMessage || INVALID_MESSAGE)
  return validation
}

const fieldTypeValidators = {
  TEXT: genericStringValidator,
  TEXTAREA: genericStringValidator,
  EMAIL: (field) =>
    genericStringValidator(field).email(
      field.errorMessage || 'Invalid email format'
    ),
  PHONE: genericStringValidator,
  SELECT: (field) => {
    if (!field.choices)
      throw new Error(`${field.id} of type SELECT has no "choices" property`)

    return genericStringValidator(field).oneOf(
      field.choices.map((choice) => choice.value)
    )
  },
  CONSENT: (field) =>
    Yup.bool().oneOf(
      [true],
      field.errorMessage ||
        'You will need to accept these terms before continuing'
    ),
  CAPTCHA: (field) =>
    Yup.bool().oneOf([true], field.errorMessage || 'Invalid captcha'),
}

const genericStringDefaultValue = (field) => field.defaultValue ?? ''

const fieldTypeDefaultValues = {
  TEXT: genericStringDefaultValue,
  TEXTAREA: genericStringDefaultValue,
  EMAIL: genericStringDefaultValue,
  PHONE: genericStringDefaultValue,
  SELECT: genericStringDefaultValue,
  CONSENT: () => false,
  CAPTCHA: () => false,
}

export const buildValidationSchema = (field) => {
  if (!fieldTypeValidators.hasOwnProperty(field.type)) {
    throw new Error(
      `Field of type ${field.type} has no implemented validation logic.`
    )
  }

  return fieldTypeValidators[field.type](field)
}

export const buildDefaultValue = (field) => {
  if (!fieldTypeDefaultValues.hasOwnProperty(field.type)) {
    throw new Error(
      `Field of type ${field.type} has no implemented default value logic.`
    )
  }

  return fieldTypeDefaultValues[field.type](field)
}

export const extractFields = (fields) => {
  const { values, validation } = fields.reduce(
    (reducer, field) => ({
      values: {
        ...reducer.values,
        [String(field.id)]: buildDefaultValue(field),
      },
      validation: {
        ...reducer.validation,
        [String(field.id)]: buildValidationSchema(field),
      },
    }),
    {
      values: {},
      validation: {},
    }
  )
  return {
    values,
    validation: Yup.object().shape(validation),
  }
}
