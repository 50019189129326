import * as React from 'react'
import { createRef, useEffect, useState } from 'react'
import { Field, Form, Formik } from 'formik'
import { post } from 'aws-amplify/api'
import { extractFields } from './functions'
import FieldWrapper from './field-wrapper'
import { gravityFormPropType } from '../../prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import { Label, SelectField, TextAeraField } from '../forms/style'
import { CheckBoxLabel } from './style'
import parse from 'html-react-parser'
import { contactFormToast } from '../toasts/contact'
import {
  enteredFieldValue,
  failedSubmissionEvent,
  successfullSubmissionEvent,
} from '../google-tag-manager/events'
import spinner from '../../images/Ellipse.png'

const handleFieldChange = (event, setFieldError, handleChange) => {
  setFieldError(event.target.name, undefined)
  handleChange(event)
}

const hasError = (name, errors, touched) => {
  return Boolean(errors?.[name]) && Boolean(touched?.[name])
}

const getError = (name, errors, touched) => {
  if (!hasError(name, errors, touched)) return undefined

  const error = errors?.[name]

  if (typeof error === 'string') return error

  return 'An unknown validation error occurred.'
}

const selectDefaultValue = (urlParam, options, defaultValue, placeholder) => {
  const list = options.map((item) => item.value)
  const matches = list.includes(urlParam)
  if (matches) {
    return urlParam
  } else if (defaultValue) {
    return defaultValue
  } else if (placeholder) {
    return 'placeholder'
  }
}

const GravityForm = ({ gravityForm, location }) => {
  const [initialValues, setInitialValues] = useState(null)
  const [validationSchema, setValidationSchema] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const recaptchaRef = createRef()
  const [urlParam, setUrlParam] = useState('default')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const lab = params.get('lab')
    setUrlParam(lab)
  }, [])

  useEffect(() => {
    if (!gravityForm) {
      throw new Error(`Gravity form data not found.`)
    }

    const { validation, values } = extractFields(gravityForm.formFields.nodes)

    setInitialValues(values)
    setValidationSchema(validation)
  }, [gravityForm])

  async function handleSubmit(values, actions) {
    const restOperation = post({
        apiName: 'gravityformsapi',
        path: `/submit/${gravityForm.formId}`,
        options: {
          body: {
            ...values,
          },
        }
      }
    )

    const { body } = await restOperation.response;
    const response = await body.json();

    if (response.success) {
      actions.setSubmitting(false)
      contactFormToast()
      actions.resetForm()
      successfullSubmissionEvent()
    } else {
      alert('An error occured whilst submitting your form.')
      failedSubmissionEvent()
    }
  }

  if (!initialValues || !validationSchema) {
    return null
  }

  if (submitted) {
    return (
      <div>
        {gravityForm.confirmations.map(({ message }) => (
          <p>{message}</p>
        ))}
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange={true}
      validateOnBlur={true}
      validate={() => recaptchaRef?.current?.execute()}
    >
      {({
        values,
        errors,
        touched,
        setFieldError,
        handleChange,
        handleBlur,
        handleSubmit: formikSubmit,
        isValid,
        setFieldValue,
        submitCount,
        isSubmitting,
      }) => (
        <Form noValidate={true} onSubmit={formikSubmit} className={'w-full'}>
          <div className={'grid gap-4'}>
            {gravityForm.formFields.nodes.map((field) => {
              const id = String(field.id)

              if (['EMAIL', 'TEXT', 'PHONE'].includes(field.type)) {
                return (
                  <FieldWrapper
                    key={id}
                    visibility={field.visibility}
                    columnSpan={field.columnSpan ?? 12}
                  >
                    <Label
                      htmlFor={id}
                      touched={hasError(id, errors, touched) ? 1 : 0}
                      errors={hasError(id, errors, touched) ? 1 : 0}
                    >
                      {field.label}*
                    </Label>
                    <Field
                      className={`border-1 w-full border-gray-300 rounded-lg focus:border-orange-light focus:outline-none focus:ring-0 form-input placeholder-gray-700
                  ${
                    hasError(id, errors, touched)
                      ? 'bg-error/30 border-2 border-orange-light text-orange-dark m-0'
                      : 'bg-white m-px'
                  }`}
                      type={field.type.toLowerCase()}
                      name={id}
                      placeholder={field.placeholder}
                      onChange={(event) =>
                        handleFieldChange(event, setFieldError, handleChange)
                      }
                      onBlur={(event) => {
                        handleBlur(event, setFieldError, handleChange)
                        if (values[id].length > 0) {
                          enteredFieldValue(field.label)
                        }
                      }}
                      touched={hasError(id, errors, touched) ? 1 : 0}
                      errors={hasError(id, errors, touched) ? 1 : 0}
                      aria-invalid={hasError(id, errors, touched)}
                      aria-errormessage={getError(id, errors, touched)}
                    />
                  </FieldWrapper>
                )
              } else if (field.type === 'TEXTAREA') {
                return (
                  <FieldWrapper
                    key={id}
                    visibility={field.visibility}
                    columnSpan={field.columnSpan ?? 12}
                    className={'flex flex-col gap-2'}
                  >
                    <Label
                      htmlFor={id}
                      touched={hasError(id, errors, touched) ? 1 : 0}
                      errors={hasError(id, errors, touched) ? 1 : 0}
                    >
                      {field.label}*
                    </Label>
                    <TextAeraField
                      name={id}
                      required={field.isRequired}
                      placeholder={field.placeholder}
                      value={values[id]}
                      rows={'10'}
                      touched={hasError(id, errors, touched) ? 1 : 0}
                      errors={hasError(id, errors, touched) ? 1 : 0}
                      onChange={(event) =>
                        handleFieldChange(event, setFieldError, handleChange)
                      }
                      onBlur={(event) => {
                        handleBlur(event, setFieldError, handleChange)
                        if (values[id].length > 0) {
                          enteredFieldValue(field.label)
                        }
                      }}
                      aria-invalid={hasError(id, errors, touched)}
                      aria-errormessage={getError(id, errors, touched)}
                    />
                    <p
                      className={'text-xs'}
                      dangerouslySetInnerHTML={{ __html: field.description }}
                    />
                  </FieldWrapper>
                )
              } else if (field.type === 'SELECT') {
                return (
                  <FieldWrapper
                    key={id}
                    visibility={field.visibility}
                    columnSpan={field.columnSpan ?? 12}
                    className={'flex flex-col gap-2'}
                  >
                    <Label
                      htmlFor={id}
                      touched={hasError(id, errors, touched) ? 1 : 0}
                      errors={hasError(id, errors, touched) ? 1 : 0}
                    >
                      {field.label}*
                    </Label>

                    <SelectField
                      id={id}
                      required={field.isRequired}
                      placeholder={field.placeholder}
                      name={id}
                      onChange={handleChange}
                      touched={hasError(id, errors, touched) ? 1 : 0}
                      errors={hasError(id, errors, touched) ? 1 : 0}
                      defaultValue={selectDefaultValue(
                        urlParam,
                        field.choices,
                        field.defaultValue,
                        field.placeholder
                      )}
                    >
                      {field.placeholder && (
                        <option
                          className={'text-blue-dark/70'}
                          disabled
                          hidden
                          value={'placeholder'}
                        >
                          {field.placeholder}
                        </option>
                      )}
                      {field.choices.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.text}
                        </option>
                      ))}
                    </SelectField>
                    <p
                      className={'text-xs'}
                      dangerouslySetInnerHTML={{ __html: field.description }}
                    />
                  </FieldWrapper>
                )
              } else if (field.type === 'CONSENT') {
                return (
                  <FieldWrapper
                    key={id}
                    visibility={field.visibility}
                    columnSpan={field.columnSpan ?? 12}
                    className={'flex flex-col gap-2'}
                  >
                    <div className={'flex flex-row items-center gap-2'}>
                      <Field
                        className={'rounded'}
                        type={'checkbox'}
                        id={id}
                        name={id}
                        required={field.isRequired}
                        onBlur={() => {
                          if (values[id]) {
                            enteredFieldValue(field.checkboxLabel)
                          }
                        }}
                      />
                      <CheckBoxLabel
                        errors={hasError(id, errors, touched)}
                        htmlFor={id}
                      >
                        {parse(field.checkboxLabel)}*
                      </CheckBoxLabel>
                    </div>
                    <p
                      className={'text-xs'}
                      dangerouslySetInnerHTML={{ __html: field.description }}
                    />
                  </FieldWrapper>
                )
              } else if (field.type === 'CAPTCHA') {
                return (
                  <ReCAPTCHA
                    className={'invisible'}
                    key={id}
                    ref={recaptchaRef}
                    sitekey={'6LfIfVkhAAAAANmWuKxc-hDUB2Jz442JU-jsYdBd'}
                    onChange={() => setFieldValue(id, true)}
                    size={'invisible'}
                    badge={field.captchaBadgePosition
                      .toLowerCase()
                      .replaceAll('_', '')}
                    onErrored={(e) => console.error(e)}
                  />
                )
              }
            })}
            {!isValid && submitCount > 0 && (
              <div className={'rounded-lg bg-error/40 px-5 py-4 col-span-12'}>
                <p className={'text-errortext font-medium text-sm'}>
                  Ett eller flere felt er feil eller ikke fylt inn. Vennligst
                  prøv på nytt.
                </p>
              </div>
            )}
            <div className={'flex col-span-12 justify-start md:justify-end'}>
              <div className={'w-36'}>
                <button
                  type={'submit'}
                  className={
                    'w-full font-medium pt-[14px] pb-[16px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light bg-blue-main text-blue-lightest hover:bg-blue-hover active:bg-blue-dark'
                  }
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <img
                      src={spinner}
                      alt={'spinner'}
                      className={'animate-spin mx-auto'}
                    />
                  ) : (
                    <p>{gravityForm.submitButton.text}</p>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

GravityForm.propTypes = {
  form: gravityFormPropType,
}

export default GravityForm
