import tw, { styled } from 'twin.macro'
import { Field } from 'formik'

export const baseClasses = tw`
w-full border-gray-300 border rounded-lg focus:border-orange-light focus:outline-none bg-white focus:ring-0 placeholder-gray-700
`

export const Textfield = styled(Field)(({ touched, errors }) => [
  baseClasses,
  touched &&
    errors &&
    tw`bg-error/30 border-2 rounded-lg border-orange-light text-orange-dark`,
])

export const TextAeraField = styled.textarea(({ touched, errors }) => [
  baseClasses,
  tw`h-full m-px`,
  touched &&
    errors &&
    tw`bg-error/30 border-2 rounded-lg border-orange-light text-orange-dark m-0`,
])

export const SelectField = styled.select(({ touched, errors }) => [
  tw`w-full border-gray-300 border rounded-lg focus:border-orange-light focus:outline-none bg-white focus:ring-0 placeholder-gray-700`,
  touched && errors
    ? tw`bg-error/30 border-2 rounded-lg border-orange-light text-orange-dark m-0`
    : tw`text-black m-px`,
])

export const Label = styled.label(({ touched, errors }) => [
  tw`text-base font-medium pb-2.5`,
  touched && errors ? tw`text-required` : tw`text-black`,
])

export const SubmitButton = styled.button`
  ${tw`w-full pt-[14px] pb-[16px] rounded-full text-[17px] leading-[17px] border-2 border-transparent focus:ring focus:ring-orange-light bg-blue-main text-blue-lightest hover:bg-blue-hover active:bg-blue-dark`}
`

